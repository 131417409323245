import React, { Component } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

export default class BannerSplitPhotoText extends Component {
  bannerClassImage = this.props.reverseDirection?'col-md-6 col-no-padding order-0 order-md-2':'col-md-6 col-no-padding'
  bannerClassText = this.props.reverseDirection?'col-md-6 text-align-right order-0 order-md-1':'col-md-6'

  render() {
    return (
      <div className="row align-items-center banner-split-photo-text">
        <div className={this.bannerClassImage}>
          <GatsbyImage image={this.props.image} alt={this.props.imageAlt} />
        </div>
        <div className={this.bannerClassText} style={{'padding': '4%'}}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
